import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Layout, { ThreeCols } from "../components/layout"
import styled from "styled-components"
import {
  Avantages,
  Spacer,
  Title,
  Input,
} from "../components/banners"
import { Submitter } from "../components/submitter"
import { useForm } from "../hooks"
import { setShipping } from "../actions"
import { validEmail, validString, validZip } from "../utils/utils"

const Form = () => {
  const member = useSelector(state => state.member)
  const cart = useSelector(state => state.cart)
  const ship = member && member.shipping
  const initialState = {
      firstname: member && member.firstname,
      lastname: member && member.lastname,
      phone: member && member.phone,
      email: member && member.email,
      option: "normal",
      ...ship // will override previous fields if present
  }
  const validate = (s, _errors) => {
    if (s.option != 'store') {
        if (!validString(s.street1)) {
            _errors.street1 = "Veuillez saisir au moins la rue."
        }
        if (!validZip(s.zip)) {
            _errors.zip = "Veuillez saisir un code postal français valide."
        }
        if (!validString(s.city)) {
            _errors.city = "Veuillez saisir la ville."
        }
    }
    if (!validEmail(s.email)) {
      _errors.email =
        "Veuillez saisir une adresse email valide pour le suivi de livraison."
    }
   if (!validString(s.firstname)) {
      _errors.firstname = "Veuillez saisir le prénom."
    }
    if (!validString(s.lastname)) {
      _errors.lastname = "Veuillez saisir le nom."
    }
    if (!validString(s.phone)) {
      _errors.phone =
        "Veuillez saisir un numéro de téléphone. Nous ne l'utiliserons qu'en cas de problème avec votre commande."
    }
  }

  const [u, submit, errors, s, setS] = useForm(validate, setShipping, initialState)

  const price = (option) => {
      if (!(cart && cart.shippingFees)) return "" 
      const s = cart.shippingFees[option]
      return (s == 0) ? "Gratuit" : (s + " €")
  }

  return (
    <div>
      <div>
        <Title>Livraison</Title>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ShippingOption
            title="Livraison rapide"
            subtitle="Collissimo"
            delay="2 à 5 jours"
            price={price("normal")}
            selected={s.option == "normal"}
            onClick={() => setS({ ...s, option: "normal" })}
          />
          <ShippingOption
            title="Retrait magasin"
            delay="2 heures"
            price="Gratuit"
            selected={s.option == "store"}
            onClick={() => setS({ ...s, option: "store" })}
          />
          <ShippingOption
            title="Livraison express"
            subtitle="Chronopost"
            delay="2 jours"
            price={price("express")}
            selected={s.option == "express"}
            onClick={() => setS({ ...s, option: "express" })}
          />
         <ShippingOption
            title="Coursier"
            subtitle="Paris uniquement"
            delay="Dans la journée"
            price={price("coursier")}
            selected={s.option == "coursier"}
            onClick={() => setS({ ...s, option: "coursier" })}
          />
        </div>
      </div>
      <Spacer v="20px" />
      <ThreeCols shippingOption={s.option}>
        <div style={{ border: "1px solid black", padding: "15px 30px" }}>
        <Spacer v="1rem" />
        <Input label="Prénom" {...u("firstname")} />
        <Spacer v="1.5rem" />
        <Input label="Nom" {...u("lastname")} />
        <Spacer v="3.5rem" />

        {s.option != 'store' && (
        <div><Input label="Adresse ligne 1" {...u("street1")} />
        <Spacer v="1.5rem" />
        <Input label="Adresse ligne 2" {...u("street2")} />
        <Spacer v="1.5rem" />
        <Input label="Code postal" {...u("zip")} />
        <Spacer v="1.5rem" />
        <Input label="Ville" {...u("city")} />
        <Spacer v="1.5rem" />
        <Input label="Instructions complémentaires" {...u("info")} />
        <Spacer v="1.5rem" /></div>)}

        <Input
          label="Numéro de mobile"
          subLabel="Utilisé uniquement en cas de problème avec votre commande"
          {...u("phone")}
        />
        <Spacer v="1.5rem" />
        <Input label="Email" {...u("email")} />
        <Spacer v="1.5rem" />

        <Submitter error={errors.top} action={submit} style={{width: '100%'}}>Valider</Submitter>

      </div>
      </ThreeCols>
      </div>
  )
}

const StyledShippingOption = styled.div`
  flex-basis: 220px;
  flex-shrink: 0;
  flex-grow: 0;
  max-width: 220px;
  height: 220px;
  margin: 0 10px;
  border: ${p => (p.selected ? "2px solid black" : "1px solid gray")};
  cursor: ${p => (p.selected ? "default" : "pointer")};
  text-align: center;
  letter-spacing: 0.1em;
`
const StyledShippingOptionTitle = styled.div`
  margin: 1.5rem 0 0rem 0;
  font-weight: bold;
`
const Circle = styled.div`
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 1px solid black;
  background-color: ${p => (p.selected ? "black" : "transparent")};
  border-radius: 50%;
  margin-top: 2rem;
`

const ShippingOption = ({
  title,
  subtitle,
  delay,
  price,
  selected,
  onClick,
}) => (
  <StyledShippingOption selected={selected} onClick={onClick}>
    <StyledShippingOptionTitle>{title}</StyledShippingOptionTitle>
    <div>{subtitle || "\u00A0"}</div>
    <div>{delay || "\u00A0"}</div>
    <StyledShippingOptionTitle>{price}</StyledShippingOptionTitle>
    <div>
      <Circle selected={selected}> </Circle>
    </div>
  </StyledShippingOption>
)

export default () => {
  return (
    <Layout>
      <Form />
      <Spacer v="6rem" />
      <Avantages />
    </Layout>
  )
}
